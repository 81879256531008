import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { SUBSCRIBE_NOTIFICATION } from '@graphql/notification/notification.mutation'
import { PUSH_NOTIFICATION_KEY, REACT_APP_ENV } from '@shared/constants'
import { getCookie, setCookie } from '@shared/utils'

const NotificationComponents = dynamic(() => import('@shared/components/notification'))

function Firebase() {
  // const firebaseConfig = {
  //   apiKey: 'AIzaSyCGN0k114OeJt2HpjsYGugkhJw00HO8kRI',
  //   authDomain: 'crictracker-stag.firebaseapp.com',
  //   projectId: 'crictracker-stag',
  //   storageBucket: 'crictracker-stag.appspot.com',
  //   messagingSenderId: '1006465797406',
  //   appId: '1:1006465797406:web:fecda0e6385580dbb50d0f',
  //   measurementId: 'G-LFJ6YN4PK5'
  // }
  const firebaseConfig = getConfig()
  const notificationKey = 'BFHsEgVnNyOEtsSsM0jqrt_AxkRLzy9RgTX50TZO_1lo6EYmFT57wjpnbD7sTqPpqvtWiBnDsMHwlGnDc_csHow'
  const router = useRouter()
  const [showNotification, setShowNotification] = useState(false)
  const [subscribe] = useMutation(SUBSCRIBE_NOTIFICATION)

  function getConfig() {
    if (REACT_APP_ENV === 'production') {
      return {
        apiKey: 'AIzaSyDJDYZL8rFl-xmJ4OVw06AOWoMdn116Yg0',
        authDomain: 'crictracker--app.firebaseapp.com',
        projectId: 'crictracker--app',
        storageBucket: 'crictracker--app.appspot.com',
        messagingSenderId: '254996609115',
        appId: '1:254996609115:web:18aac3b3a12ffc38e0677e',
        measurementId: 'G-DGR0VMJ8M6'
      }
    } else {
      return {
        apiKey: 'AIzaSyCGN0k114OeJt2HpjsYGugkhJw00HO8kRI',
        authDomain: 'crictracker-stag.firebaseapp.com',
        projectId: 'crictracker-stag',
        storageBucket: 'crictracker-stag.appspot.com',
        messagingSenderId: '1006465797406',
        appId: '1:1006465797406:web:fecda0e6385580dbb50d0f',
        measurementId: 'G-LFJ6YN4PK5'
      }
    }
  }

  function askForPermissionToReceiveNotifications() {
    try {
      // const r = await navigator.serviceWorker.ready
      // console.log(r)
      if ('Notification' in window) {
        Notification?.requestPermission()?.then(async (permission) => {
          if (permission === 'granted') {
            try {
              const { initializeApp } = await import('firebase/app')
              const app = initializeApp(firebaseConfig)

              const { getMessaging, getToken, onMessage } = await import('firebase/messaging')

              const messaging = getMessaging(app)
              const sToken = await getToken(messaging, {
                vapidKey: REACT_APP_ENV === 'production' ? PUSH_NOTIFICATION_KEY : notificationKey
              })

              // const pushToken = localStorage.getItem('pushToken')
              subscribe({ variables: { input: { sToken } } })
              localStorage.setItem('pushToken', sToken)

              onMessage(messaging, (payload) => {
                // console.log('Message received. ', payload)

                // Trigger Notification
                const notification = new Notification(payload?.notification?.title, {
                  body: payload?.notification?.body,
                  icon: payload?.notification?.icon || 'https://www.crictracker.com/images/pwa/icon-512x512.png',
                  image: payload?.notification?.image
                })

                notification.onclick = () => {
                  router.push('/' + payload?.data?.sSlug + '/')
                  notification.close()
                }
              })
            } catch (error) {
              console.log(error)
            }
          }
        })
      }
    } catch (error) {
      console.error('Browser not support notification', error)
    }
  }

  useEffect(() => {
    if (!router?.query?.isMobileWebView && 'Notification' in window) {
      const isNotificationAsked = getCookie('isNotificationAsked')
      const permission = Notification?.permission
      if (!isNotificationAsked && permission !== 'granted' && permission !== 'denied') {
        setShowNotification(true)
      } else if (permission === 'granted') {
        askForPermissionToReceiveNotifications()
      }
    }
  }, [])
  if (showNotification) {
    return (
      <NotificationComponents
        onYes={() => {
          askForPermissionToReceiveNotifications()
          setShowNotification(false)
        }}
        onNo={() => {
          setCookie({ cName: 'isNotificationAsked', cValue: true, exDays: 2 })
          setShowNotification(false)
        }}
      />
    )
  } else return null
}
export default Firebase
